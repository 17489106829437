// Margin
@media (max-width: $screen-xs-max) {
    .mb-5-xs {
        margin-bottom: 5px;
    }
}

// Padding
@media (min-width: $screen-sm-min) {
    .pt-30-sm {
        padding-top: 30px;
    }
}

.force-break {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // -webkit-hyphens: auto;
    // hyphens: auto;
}

.block {
  display: block;
}
.inline-block {
    display: inline-block;
}
.display-flex {
    @include flexbox();
}
.flex {
    @include flex(1);
}

.round {
  border-radius: 50%;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.codefont {
  font-family: monospace;
}


.glyph-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
