.navbar-toggle {
    float: left;
    margin-left: $navbar-padding-horizontal;

    .navbar-default & {
        border-color: transparent;
    }
}

@media (max-width: $screen-xs-max) {
    .navbar > .container > .navbar-right {
        > .navbar-nav {
            margin-bottom: 0;
            margin-right: 0;
            margin-top: 0;

            > li > a {
                padding-bottom: $navbar-padding-vertical;
                padding-top: $navbar-padding-vertical;
            }

            .open .dropdown-menu {
                background-color: $dropdown-bg;
                border: 1px solid $dropdown-fallback-border; // IE8 fallback
                border: 1px solid $dropdown-border;
                position: absolute;
                @include box-shadow(0 6px 12px rgba(0,0,0,.175));
            }
        }
    }
}