// Grid
// $grid-gutter-width: 20px;

// Typography
$font-size-base: 14px;
$line-height-base: 1.6;


// Bootstrap 4
$enable-rounded:           true !default;
$enable-hover-media-query: false !default;

$grid-breakpoints: (
  xs: 0,
  sm: $screen-xs-min,
  md: $screen-sm-min,
  lg: $screen-md-min,
  xl: $screen-lg-min
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

