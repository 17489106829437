.inline-gutter {
    > .row, &.row {
        margin-left: -($grid-gutter-width / 4);
        margin-right: -($grid-gutter-width / 4);

        > [class *="col-"] {
            padding-left: ($grid-gutter-width / 4);
            padding-right: ($grid-gutter-width / 4);
        }
    }
}

.flex-row {
    &:before, &:after {
        display: none;
    }

    @include flexbox();
    @include flex-flow(row wrap);
    @include align-content(flex-start);
}
.flex-col {
    float: none;

    @include flex(0, 0, auto);
}