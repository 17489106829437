.panel-subheading {
    background-color: $laravel-border-color;
    font-size: 11px;
    font-weight: bold;
    padding: 5px $panel-body-padding;
    text-transform: uppercase;
}

.panel-knockout {
    background-color: lighten($brand-primary, 45%);
    border-bottom: 1px solid $laravel-border-color;
    border-top: 1px solid $laravel-border-color;
    margin-bottom: $form-group-margin-bottom;
    margin-left: -$panel-body-padding;
    margin-right: -$panel-body-padding;
    padding: $panel-body-padding;

    &:first-child {
        border-top-width: 0;
        margin-top: -$panel-body-padding;
    }
    &:last-child {
        border-bottom-width: 0;
        margin-bottom: -$panel-body-padding;
    }
}

.display-flex {
    > .panel {
        max-width: 100%;
    }
}