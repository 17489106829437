.name-column {

    > a {
        @include flexbox;
        @include flex-direction(row);
        @include align-items(center);

        > img {
            @include flex(0, 0, 25px);
        }

        > span {
            padding-left: 5px;
        }
    }

}