// alt-select
.form-control {
    @at-root textarea#{&} {
        resize: vertical;
    }
    @at-root select#{&}:not([multiple]) {
        -moz-appearance: none;
        -webkit-appearance: none;

        background-image:
            linear-gradient(45deg, transparent 50%, lighten($text-color, 20%) 50%),
            linear-gradient(135deg, lighten($text-color, 20%) 50%, transparent 50%),
            linear-gradient(to right, $input-border, $input-border);
        background-position:
            calc(100% - 19px) calc(1em + 1px),
            calc(100% - 14px) calc(1em + 1px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
        padding-right: 35px;

        &.input-sm {
            background-position:
                calc(100% - 14px) calc(1em + 1px),
                calc(100% - 9px) calc(1em + 1px),
                calc(100% - 2.25em) 0.5em;
            line-height: 18px;
            padding-right: 28px;
        }
    }
}

.form-section-title {
    color: darken($body-bg, 20%);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
}

.replicate {
    margin-bottom: 5px;
}

.form-horizontal {
    .help-block {
        margin-bottom: 0;
    }
}

// file inputs
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.form-control-wrap {
    margin-bottom: $form-group-margin-bottom;
    position: relative;

    label {
        cursor: text;
        line-height: 1.6;
        margin: 0;
        padding: 7px 12px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all 250ms ease;
    }

    input:focus, input:valid {
        + label {
            font-size: 11px;
            padding: 0;
            top: -1.6em;
        }
    }
}

.photo-actions {
    margin-bottom: 20px;
}
.speaker-photo-wrap {
    border-radius: 6px;
    box-shadow: 2px 2px 20px -2px rgba(0, 0, 0, 0.15);
    margin: 0 auto 20px auto;
    max-width: 250px;
    overflow: hidden;

    > .glyphicon {
        color: #000;
        display: none;
        font-size: 60px;
        left: 50%;
        margin: -30px 0 0 -30px;
        opacity: 0;
        position: absolute;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        top: 50%;
    }

    &.processing {
        > .speaker-photo {
            opacity: 0.5;
        }
        > .glyphicon {
            display: block;
            opacity: 0.5;
        }
    }
}
.speaker-photo {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding-bottom: 100%;
    width: 100%;
    transition: opacity 350ms ease;
}

