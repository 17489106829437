.list-group-item {
    .meta {
        font-weight: bold;
    }

    .item-toolbar {
        display: none;
        position: absolute;
        right: -1px;
        top: -1px;
    }

    .handle {
        background-color: $input-group-addon-bg;
        border: 1px solid $input-group-addon-border-color;
        border-radius: $list-group-border-radius;
        color: $text-muted;
        cursor: move;
        cursor: ns-resize;
        display: inline-block;
        height: 25px;
        flex: none;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 4px;
        text-align: center;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.35);
        width: 13px;
        z-index: 10;

        &:before {
            content: '.. .. .. .. ..';
            display: block;
            margin-left: 1px;
            margin-top: -2px;
        }

        &:hover, &:active {
            background-color: darken($input-group-addon-bg, 5%);
            color: darken($text-muted, 10%);
        }
    }
    > .handle {
        left: -7px;
        position: absolute;
        top: 4px;
    }

    &:hover {
        &:not([class*="sortable-"]) {
            .item-toolbar {
                display: block;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        .item-toolbar {
            display: block;
        }
    }
}

.alterable {
    .list-group-item-heading, .media-heading {
        padding-right: 2.75em;
    }
}


// media-group
.media-group-item {
    padding: 0;

    &:first-child {
        &, & > :first-child {
            border-top-left-radius: 4px;
        }
    }
    &:last-child {
        &, & > :last-child {
            border-bottom-left-radius: 4px;
        }
    }

    .media {
        display: flex;
        flex-direction: row;
        margin: 0;
    }

    .media-handle {
        align-items: flex-start;
        display: flex;
        flex: 0 0 20px;
        justify-content: center;
        text-align: center;
    }

    .media-left {
        align-items: center;
        flex: 0 0 100px;
        min-height: 75px;
        padding: 0;

        .media-object {
            background-color: transparent;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
        }
    }

    .media-body {
        display: block;
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
        width: auto;

        > .content {
            padding: 10px 20px 10px 10px;
        }

        a {
            color: $brand-success;
            display: inline-block;
            font-size: 0.85em;
            font-style: italic;
            line-height: 1.3;
        }
    }

    .provider {
        background-color: transparent;
        background-position: right center;
        background-repeat: no-repeat;
        height: 20px;
        opacity: 0.4;
        position: absolute;
        bottom: 0;
        right: 2px;
        width: 25px;

        &.youtube {
            background-image: url('https://cdnjs.cloudflare.com/ajax/libs/simple-icons/3.0.1/youtube.svg');
        }
        &.vimeo {
            background-image: url('https://cdnjs.cloudflare.com/ajax/libs/simple-icons/3.0.1/vimeo.svg');
        }
    }
}

.list-group-item-form {
    background-color: $panel-footer-bg;
    border-top: 1px solid lighten($panel-inner-border, 6%);
    margin-bottom: -10px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 10px;
    padding: 10px 15px;
}