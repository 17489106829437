.nav-pills-short {
    > li {
        > a {
            padding: 5px 15px;
        }
    }
}

.panel-nav-wrapper {
    background-color: lighten($brand-primary, 45%);
    height: 37px;
    overflow-y: hidden;
    position: relative;

    &:before {
        border-bottom: 1px solid $laravel-border-color;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
    }

    &:first-child {
        border-top-left-radius: $panel-border-radius;
        border-top-right-radius: $panel-border-radius;
    }
}
.panel-nav {
    height: 80px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    text-align: left;
    white-space: nowrap;
    z-index: 10;

    -webkit-overflow-scrolling: touch;
}

.panel-nav-tabs {
    border-bottom: 0;
    display: inline-block;
    margin: 0;
    padding-left: $panel-body-padding;
    padding-right: $panel-body-padding;
    padding-bottom: 1px;
    position: relative;
    transition: padding 250ms ease-out;

    &:before, &:after {
        display: none;
    }

    > li {
        display: inline-block;
        float: none;
        margin-right: 40px;
        padding-bottom: 8px;
        padding-top: 8px;

        &:first-child {
            padding-left: 4px;
        }
        &:last-child {
            margin-right: 0;
            padding-right: 4px;
        }

        &.active {
            border-bottom: 1px solid #333;

            > a {
                &, &:hover, &:focus, &:active {
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    cursor: inherit;
                }
            }
        }

        > a {
            display: inline-block;
            margin: 0;
            padding: 0;
            position: relative;

            &, &:hover, &:focus, &:active {
                background-color: transparent;
                border: 0;
            }
        }
    }
}