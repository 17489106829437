.note-editor.note-frame {
    border-color: $input-border !important;

    .has-error & {
        border-color: #a94442 !important;
    }
}

.panel-heading.note-toolbar {
    border-color: $input-border;
}

.modal.link-dialog {
    .form-group {
        margin-left: 0;
        margin-right: 0;
    }
}